import React from 'react';
import finance from '../../assets/finance.png'
function FinanceScreen() {

    return (
        <div className="finance-container">
         <img src={finance} alt="finance" width="100%" height="100%" />
        </div>
    );
}


export default FinanceScreen;

