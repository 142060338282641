import React from 'react';
import reporting from '../../assets/report.png';

function ReportingScreen() {

    return (
        <div className="reporting-container">
            <img src={reporting} alt="reporting" width="100%" height="100%" />

        </div>
    );
}


export default ReportingScreen;

