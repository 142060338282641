import React from 'react';
import { connect } from 'react-redux';
import dashboardImage from "../../assets/dashboard.png"

import { logOut } from '../../Store/Middlewares/middlewares';

function DashboardScreen() {
    return (
        <div className="dashboardContainer">
            <img src={dashboardImage} alt="reporting" width="100%" height="100%" />           
        </div>
    );
}


function mapDispatchToProps(dispatch) {
    return ({
        logOutAction: () => { dispatch(logOut()) },
    })
}
export default connect(null, mapDispatchToProps)(DashboardScreen);