import firebase from 'firebase';

var firebaseConfig = {
    apiKey: "AIzaSyDRF3ra2H8SGbPIh-7Rho97b1uOFHmqfxA",
    authDomain: "ppmone-8f1ec.firebaseapp.com",
    databaseURL: "https://ppmone-8f1ec-default-rtdb.firebaseio.com",
    projectId: "ppmone-8f1ec",
    storageBucket: "ppmone-8f1ec.appspot.com",
    messagingSenderId: "858400253954",
    appId: "1:858400253954:web:6473030462bbdb620b9f1b",
    measurementId: "G-1HM177DW5H"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);