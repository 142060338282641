import { ActionTypes } from '../Actions/actions';
import firebase from 'firebase';

let redirect = {};
let auth = firebase.auth();
let database = firebase.database().ref();
let signUpTemplate = {}
export let projectTemplate = {}
export let bucketTemplate = []
export let tasksTemplate = []
export let tasks = []





export function setNavigationProps(navigation) {
    return dispatch => {
        redirect = navigation;


    }
}
export function microsoftLogIn() {
    return dispatch => {
        var provider = new firebase.auth.OAuthProvider('microsoft.com');
        provider.setCustomParameters({
            // Optional "tenant" parameter in case you are using an Azure AD tenant.
            // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
            // or "common" for tenant-independent tokens.
            // The default value is "common".
            tenant: 'df236dfc-ca99-4c96-b450-34d0ce02b57a'
        });

        auth.signInWithPopup(provider)
            .then((result) => {
                // IdP data available in result.additionalUserInfo.profile.
                // ...

                /** @type {firebase.auth.OAuthCredential} */

                const { displayName, mail } = result.additionalUserInfo.profile
                const { uid } = result.user
                const user = {
                    name: displayName,
                    email: mail,
                    company: ''
                }

                const paymentDetails = {
                    fullName: '',
                    address: '',
                    city: '',
                    state: '',
                    postCode: '',
                    country: '',

                    cardNumber: '',
                    cardholderName: '',
                    expiryDate: '',
                    cvv: '',
                }

                dispatch(setUserData(uid, user))
                dispatch(setUserPaymentDetails(uid, paymentDetails))

            })
            .catch((error) => {
                // Handle error.
            });

    }
}

export function signIn(email, password) {

    return dispatch => {
        dispatch({ type: ActionTypes.SIGNIN_SUCCESS, payload: true })

        auth.signInWithEmailAndPassword(email, password).then(() => {
            dispatch(getCurrentUserData())
            dispatch({ type: ActionTypes.SIGNIN_SUCCESS, payload: false })

            redirect.push('/home/dashboard')

        }).catch(error => {
            alert(error.message)
            dispatch({ type: ActionTypes.SIGNIN_SUCCESS, payload: false })

        })

    }
}

export function signUp(paymentDetails) {

    return dispatch => {

        dispatch({ type: ActionTypes.SIGNUP_SUCCESS, payload: true })

        auth.createUserWithEmailAndPassword(signUpTemplate.email, signUpTemplate.password).then(ev => {

            dispatch(setUserData(ev.user.uid, signUpTemplate.user))
            dispatch(setUserPaymentDetails(ev.user.uid, paymentDetails))


        }).catch(error => {
            alert(error.message)
            dispatch({ type: ActionTypes.SIGNUP_ERROR, payload: error.message })
            dispatch({ type: ActionTypes.SIGNUP_SUCCESS, payload: false })

        })

    }
}

export function signUpSaveData(user, email, password) {

    return dispatch => {
        signUpTemplate = {
            user,
            email,
            password
        }

    }
}

export function setUserData(uid, user) {
    return dispatch => {
        database.child(`users/${uid}`).set(Object.assign({}, user, { uid: uid }))
    }
}

export function setUserPaymentDetails(uid, paymentDetails) {
    return dispatch => {
        database.child(`paymentDetails/${uid}`).set(Object.assign({}, paymentDetails, { uid: uid })).then(() => {
            dispatch({ type: ActionTypes.SIGNUP_SUCCESS, payload: false })
            redirect.push('/home/dashboard')
        })

    }
}

export function addProject() {
    return dispatch => {

        tasks.filter(a => {
            if (a.tasks.length === 0) {
                a.tasks = ['empty']
            }
            return null
        })

        getCurrentUser().then(user => {

            if (user && user?.uid !== '') {
                const userProject = Object.assign({}, projectTemplate, { createdBy: user.uid, buckets: tasks })
                userProject.forecastBurndownRange.filter(a => {
                    if (a.effort === undefined && a.financial === undefined) {
                        a.effort = '';
                        a.financial = ''
                    }
                    return null
                })


                database.child('projects').push(userProject).then(() => {
                    database.child(`projectNumber`).set({ number: firebase.database.ServerValue.increment(1) }).then(() => {
                        alert('Project Added')
                        redirect.push({
                            pathname: '/home/projects/project-list',
                            state: 'project list'
                        })
                    })

                    dispatch(clearTemplates())
                })
            }
        })


    }
}

export function clearTemplates() {
    return dispatch => {
        projectTemplate = {
            contingency: "",
            description: [],
            endDate: '',
            endDateDisplay: "",
            fixedPrice: false,
            forecastBreakdown: [],
            forecastBurndownRange: [],
            forecastHours: "",
            name: "",
            number: "",
            projectServices: "",
            startDate: '',
            startDateDisplay: "",
            timeMaterials: false,
        }
        bucketTemplate = []
        tasksTemplate = []
        tasks = []
    }
}

export function updateProjectActivity(key, updatedActivity) {
    return dispatch => {
        database.child(`projects/${key}`).update(updatedActivity).then(() => {
            redirect.push({
                pathname: '/home/projects/project-list',
                state: 'project list'
            })
        })
    }
}

export function updateProjectFinance(key, updatedFinance) {
    return dispatch => {
        if (updatedFinance.forecastBurndownRange.length === 0) {
            updatedFinance.forecastBurndownRange = ['empty']
        }
        if (updatedFinance.forecastBurndownRange !== undefined && updatedFinance.forecastBurndownRange[0] !== 'empty') {
            updatedFinance.forecastBurndownRange.filter(a => {
                if (a.effort === undefined && a.financial === undefined) {
                    a.effort = '';
                    a.financial = ''
                }
                return null
            })
        }
        database.child(`projects/${key}`).update(updatedFinance).then(() => {
            redirect.push({
                pathname: '/home/projects/project-list',
                state: 'project list'
            })
            dispatch(clearTemplates())
        })
    }
}

export function getCurrentUser() {
    return new Promise((reslove, reject) => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                reslove(user);
            }
        });
    })
}
export function getCurrentUserData() {
    return dispatch => {
        getCurrentUser().then(user => {
            if (user) {
                database.child(`users/${user.uid}`).on('value', ev => {
                    if (ev.val()) {
                        dispatch({ type: ActionTypes.GET_USER_DATA, payload: ev.val() })
                    }
                })
            }
        })

    }
}
export function getProjectNumber() {
    return dispatch => {
        database.child('projectNumber').on('value', ev => {
            if (ev.val()) {
                const { number } = ev.val()
                let newNumber = parseInt(number) + 1
                let code = 'PRO';
                let singleZero = '0';
                let doubleZero = '00';
                let proNum = '';
                if (newNumber <= 9) {
                    proNum = code + doubleZero + newNumber;
                } else if (newNumber <= 98) {
                    proNum = code + singleZero + newNumber;
                } else {
                    proNum = code + newNumber;
                }
                dispatch({ type: ActionTypes.GET_PROJECT_NUMBER, payload: { projectNumber: proNum } })
            } else {
                dispatch({ type: ActionTypes.GET_PROJECT_NUMBER, payload: { projectNumber: '001' } })

            }
        })
    }
}

export function getProjects() {
    return dispatch => {
        dispatch({ type: ActionTypes.GET_PROJECTS, payload: { projects: {}, loading: true } })
        database.child('projects').on('value', ev => {
            let obj = ev.val()
            Object.keys(obj).filter(key => {
                if (obj[key].projectState === 'complete') {
                    delete obj[key]
                }
                return null

            })
            if (ev.val()) {
                dispatch({ type: ActionTypes.GET_PROJECTS, payload: { projects: obj, loading: false } })


            } else {
                dispatch({ type: ActionTypes.GET_PROJECTS, payload: { projects: {}, loading: false } })

            }
        })

    }
}

export function setProjectTemplate(project) {
    return dispatch => {
        projectTemplate = project
    }
}
export function setBucketTemplate(bucket) {
    return dispatch => {
        if (!bucketTemplate.some(a => a.bucketID === bucket.bucketID)) {
            bucketTemplate.push(bucket)
            tasks.push(
                {
                    bucketName: bucket.bucketName,
                    bucketID: bucket.bucketID,
                    startDate: '',
                    endDate: '',
                    startDateDisplay: '',
                    endDateDisplay: '',
                    tasks: []
                }
            )
        }

    }
}

export function setTasksTemplate(tasks) {
    return dispatch => {
        tasksTemplate = tasks
    }
}
export function setTasks(task) {
    return dispatch => {
        tasks = task
    }
}
export function setSelectedProject(selectedProject) {
    return dispatch => {
        dispatch({ type: ActionTypes.SELECTED_PROJECT, payload: selectedProject })
    }
}
export function logOut() {
    return dispatch => {
        auth.signOut().then(() => {
            redirect.push('/')
        })
    }
}
