export const ActionTypes = {

    MICROSOFT_LOGIN: "MICROSOFT_LOGIN",
    SIGNIN_SUCCESS: "SIGNIN_SUCCESS",

    SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
    SIGNUP_ERROR: "SIGNUP_ERROR",

    GET_USER_DATA: "GET_USER_DATA",

    GET_PROJECT_NUMBER: "GET_PROJECT_NUMBER",
    GET_PROJECTS: "GET_PROJECTS",

    SELECTED_PROJECT: "SELECTED_PROJECT"

}